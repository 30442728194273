/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

//ion-modal.verre_pop {
//    --width: 90%;
//    min-width: 700px;
//    --height: 590px;
//}

// DIN
@font-face {
    font-family: 'DIN-BLACK';
    src: url('/assets/fonts/din_black.ttf'),
             url('/assets/fonts/din_black.woff');
}
@font-face {
    font-family: 'DIN-BOLD';
    src: url('/assets/fonts/din_bold.ttf'),
             url('/assets/fonts/din_bold.woff');
}
@font-face {
    font-family: 'DIN-MEDIUM';
    src: url('/assets/fonts/din_medium.ttf'),
             url('/assets/fonts/din_medium.woff');
}
@font-face {
    font-family: 'DIN';
    src: url('/assets/fonts/din.ttf'),
             url('/assets/fonts/din.woff');
}

// SANSATION
@font-face {
    font-family: 'Sansation_Light';
    src: url('/assets/fonts/Sansation_Light.ttf'),
             url('/assets/fonts/Sansation_Light.woff');
}
@font-face {
    font-family: 'Sansation_Regular';
    src: url('/assets/fonts/Sansation_Regular.ttf'),
             url('/assets/fonts/Sansation_Regular.woff');
}
@font-face {
    font-family: 'Sansation_Bold';
    src: url('/assets/fonts/Sansation_Bold.ttf'),
             url('/assets/fonts/Sansation_Bold.woff');
}

// DejaVu Serif
@font-face {
    font-family: 'Dejvu_Regular';
    src: url('/assets/fonts/DejaVuSerif.ttf');
}
@font-face {
    font-family: 'Dejvu_Italic';
    src: url('/assets/fonts/DejaVuSerif-Italic.ttf');
}
@font-face {
    font-family: 'Dejvu_BoldItalic';
    src: url('/assets/fonts/DejaVuSerif-BoldItalic.ttf');
}
@font-face {
    font-family: 'Dejvu_Bold';
    src: url('/assets/fonts/DejaVuSerif-Bold.ttf');
}

body {
    //font-family: 'DIN';
    font-family: 'Sansation_Regular';
}

h1 {
    //font-family: 'DIN-BLACK';
    font-family: 'Sansation_Bold';
}

h2, h3 {
    //font-family: 'DIN-BOLD';
    font-family: 'Sansation_Bold';
}

h4, h5 {
    //font-family: 'DIN-MEDIUM';
    font-family: 'Sansation_Bold';
}

p {
    //font-family: 'DIN';
    font-family: 'Dejvu_Regular';
}

ul li {
    font-family: 'Dejvu_Regular';
}

ion-modal.verre_pop {
    --width: 800px;
    --height: 500px;
}

.detail_popover::part(content) {
    background: url('assets/svg/modifs.svg') no-repeat;
    background-size: cover;
}

.detail_popover_safari::part(content) {
    background: url('assets/svg/modifs.svg') no-repeat;
    background-size: cover;
    -webkit-backdrop-filter: saturate(180%) blur(0px);
}

.detail_popover::part(backdrop), .detail_popover_safari::part(backdrop) {
    margin-top: -160px;
}

.detail_popover {
    --background: url('/assets/svg/modifs.svg') no-repeat;
    background-size: cover;
    --width: 350px;
    --height: 350px;
    --padding: 10px;
    overflow: auto;
    --backdrop-opacity: 0;
    //top: 115px; //auto
    --offset-y: 115px;
    //bottom: -115px;
    //height: 100%;
    color: gray;
    //border-radius: 125px;
    .modig_img img {
        width: 60%;
        margin: 0 0 0 50px;
    }
    &::part(content){
        border-radius: 50%;
        box-shadow: 0 5px 5px 0 rgba(0, 0, 0, .2);
    }
    &::part(arrow) {
        display: none;
    }
}

.detail_popover_safari {
    --background: url('/assets/svg/modifs.svg') no-repeat;
    background-size: cover;
    --width: 350px;
    --height: 350px;
    --padding: 10px;
    overflow: auto;
    --backdrop-opacity: 0;
    //top: 115px; //auto
    --offset-y: 115px;
    //bottom: -115px;
    //height: 100%;
    color: gray;
    //border-radius: 125px;
    .modig_img img {
        width: 60%;
        margin: 0 0 0 50px;
    }
    &::part(content){
        border-radius: 50%;
        box-shadow: 0 5px 5px 0 rgba(0, 0, 0, .2);
    }
    &::part(arrow) {
        display: none;
    }
}

.detail_popover h5, .detail_popover_safari h5 {
    text-align: center;
    margin-top: 40px;
    color: rgb(68, 68, 68);
}

.detail_popover .modig_img, .detail_popover_safari .modig_img {
    width: 50%;
    height: 200px;
    float: left;
}
/*
.detail_popover .modig_img img {
    width: 60%;
    margin: 0 0 0 50px;
}*/

.detail_popover .modif_info, .detail_popover_safari .modif_info {
    width: 50%;
    height: 200px;
    float: right;
}

ion-item::part(native) {
    background: transparent;
    padding: 0;
    color: gray;
    border: 0px;
}

ion-input .native-input  {
    background: white;
    padding: 0 10px;
    border-radius: 5px;
}

.detail_popover ion-input, .detail_popover_safari ion-input  {
    background: white;
    padding: 0 10px !important;
    border-radius: 5px;
    border: 1px solid darkgrey;
    box-shadow: inset 0 0 5px 2px rgb(0, 0, 0, .25);
}

.detail_popover .valid_detail, .detail_popover_safari .valid_detail {
    position: absolute;
    top: 210px;
    right: 40px;
}

.detail_popover .delete_detail, .detail_popover_safari .delete_detail {
    position: absolute;
    top: 210px;
    left: 40px;
}

.detail_popover .valid_detail::part(native), .detail_popover_safari .valid_detail::part(native) {
    background: limegreen;
    width: 80px;
    height: 30px;
    --background-hover: green;
}

.detail_popover .delete_detail::part(native), .detail_popover_safari .delete_detail::part(native) {
    background: red;
    width: 100px;
    height: 30px;
    --background-hover: darkred;
}

.btn_icn {
    padding-right: 5px;
}

.detail_popover .modig_img .img_repas, .detail_popover_safari .modig_img .img_repas {
    width: 80%;
    margin: auto;
    left: 0;
    right: 0;
    top: 15%;
    position: absolute;
}

ion-modal.popinfo::part(content) {
    background: transparent;
    border-radius: 20px;
    --width: 400px;
    --height: 420px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .5);
}

ion-content {
    --overflow: hidden;
}

.cran p, .verre_pop p, .hover_drink, .limite p, .highcharts-axis-labels {
    font-family: 'Sansation_Regular';
}

// RESPONSIVE 
@media screen and (max-width: 1024px){
    .active_circles .circle_select_character_wrap {
      width: 400px !important;
      height: 400px !important;
      top: 180px !important;
      left: 200px !important;

      #character {
          transform: scale(1) !important;
          top: -100px !important;
          left: 120px !important;
      }
      #info_start {
        top: 60px !important;
        left: 265px !important;
        #character_name_start {
            font-size: 22px;
        }
      }
    }
    .active_circles .circle_back_select_character_wrap.top_circle {
        width: 400px !important;
        height: 400px !important;
        top: 200px !important;
        left: 180px !important;
  
        #character {
            transform: scale(1) !important;
            top: -100px !important;
            left: 240px !important;
        }
        #info_start {
          top: 60px !important;
          left: 265px !important;
          #character_name_start {
              font-size: 22px;
          }
        }
    }
    #info_start ion-item {
        top: 0px;
        left: -25px;
        &#button_switch_perso {
            top: -40px !important;
        }
    }
    

    #left_shadow {
        left: 200px !important;
        padding-left: 70px !important;
        width: 170px !important
    }
    #right_shadow {
        width: 100px !important;
        padding-right: 50px;
    }
    ion-toolbar {
        height: 30px !important;
    }

    #timeline_wrap {
        bottom:0 !important;
        height: 200px !important;

        #interface_timeline{
            top:125px;
        }
        #jour_timeline {
            top:0;
            font-size: 14px;
        }
        #temps_timeline {
            padding-top: 15px;
            font-size: 16px;
        }
    }

    #logo {
        width: 120px !important;
        height: 120px !important;
        top: 25px !important;
        left: 25px !important;
    }

    #courbe {
        margin-left: 140px !important;
        margin-bottom: 120px !important;
        width: calc(100% - 200px) !important;
        height: calc(100% - 300px) !important;
    }

    #tuture1, #tuture2, #bus {
        bottom: 210px !important;
    }

    .active_circles #balance_wrap {
        height: 0 !important;
    }

    .highcharts-plot-line-label {
        font-size: 10px !important;
    }
}